import React from "react";
import Faqs from "../../Components/Faqs/Faqs";


function Resource() {
  return (
    <>
      <div className="pt-[80px] dark:bg-[#292929] font-serif">
        <div className="flex justify-center">
          <div className="container 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-[1200px] flex flex-wrap justify-between">
            <div className="container my-12 mx-auto px-4 md:px-12">
              <div className="flex flex-wrap -mx-1 lg:-mx-4">

              <div className="flex justify-center ">
            <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col  mb-5 rounded-lg pt-10">
              <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-3 rounded-[16px] bg-[#f3f3fd] dark:bg-[#3c3c3c] pt-5">
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                  <p className="2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 dark:text-white">Settlement Agreements</p>
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white">What is a Settlement Agreement?</p>
                  <p className=" 2xl:px-5 xl:px-5 lg:px-5 md:px-5 leading-normal text-[18px] dark:text-[#e0e0e0]">A Settlement Agreement is a legally binding <span className="font-bold">contract</span> between an employer and an employee that resolves any disputes or claims the employee might have against the employer. It typically includes terms such as the amount of compensation to be paid to the employee, any confidentiality clauses, and other agreed-upon terms and conditions. These agreements are commonly used to settle disputes arising from issues such as <span className="font-bold">redundancy</span> , <span className="font-bold">unfair dismissal</span> , <span className="font-bold">discrimination</span> , and other <span className="font-bold">workplace grievances</span> .</p>
                </div>
                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 pxm-4-4">
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white">Key Features of a Settlement Agreement:</p>
                  <ul className="list-disc ml-4 ">
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Voluntary:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Both parties must enter into the agreement willingly. There should be no coercion or undue pressure.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Confidentiality:</span> <span className="text-[17px] dark:text-[#e0e0e0]"> Often includes clauses that prevent both parties from disclosing the terms of the agreement or the circumstances leading to it.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Waiver of Claims:</span> <span className="text-[17px] dark:text-[#e0e0e0]">The employee usually agrees not to pursue any further legal claims related to the employment or its termination.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white ">Legal Advice:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Employees must receive independent legal advice for the agreement to be valid. This ensures they fully understand the terms and implications.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Compensation:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Typically includes a financial settlement, which can include severance pay, notice pay, and other benefits.</span> </li>
                  </ul>
                </div>
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white">When to Use a Settlement Agreement:</p>
                  <ul className="list-disc ml-4">
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Redundancy:</span> <span className="text-[17px] dark:text-[#e0e0e0]">To amicably resolve redundancy situations.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Dismissal:</span> <span className="text-[17px] dark:text-[#e0e0e0]">To avoid litigation in cases of unfair dismissal.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Disputes:</span> <span className="text-[17px] dark:text-[#e0e0e0]"> To settle workplace disputes, including discrimination and harassment claims.</span> </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col mb-5 rounded-lg ">
              <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-3 rounded-[16px] bg-[#f3f3fd]  dark:bg-[#3c3c3c]  pt-5">
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                  <p className="2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 dark:text-white">COT3 Agreements</p>
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white ">What is a COT3 Agreement?</p>
                  <p className="2xl:px-5 xl:px-5 lg:px-5 md:px-5 leading-normal text-[18px] dark:text-[#e0e0e0]">A COT3 agreement is a legally binding document that records the terms of a settlement reached through the Advisory, Conciliation and Arbitration Service (ACAS). It is used to resolve employment disputes without the need for a tribunal hearing. The COT3 is similar to a settlement agreement but is specifically facilitated by ACAS.</p>
                </div>
                <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white">Key Features of a COT3 Agreement:</p>
                  <ul className="list-disc ml-4">
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Facilitated by Acas:</span> <span className="text-[17px] dark:text-[#e0e0e0]">An Acas conciliator assists both parties in reaching a mutually agreeable settlement.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Legally Binding:</span> <span className="text-[17px] dark:text-[#e0e0e0]"> Often includes clauses that prevent both parties from disclosing the terms of the agreement or the circumstances leading to it.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">No Need for Legal Advice:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Unlike settlement agreements, independent legal advice is not mandatory, although it may still be beneficial.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Quick Resolution:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Often results in a quicker settlement compared to going through a tribunal.</span> </li>
                  </ul>
                </div>
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                  <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-bold mb-3 dark:text-white">When to Use a COT3 Agreement:</p>
                  <ul className="list-disc ml-4">
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Pre-Claim Conciliation:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Before a formal claim is lodged with an employment tribunal.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">During Tribunal Proceedings:</span> <span className="text-[17px] dark:text-[#e0e0e0]">To settle disputes at any stage of the tribunal process.</span> </li>
                    <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Broad Range of Disputes:</span> <span className="text-[17px] dark:text-[#e0e0e0]"> Useful for various employment disputes, including pay issues, discrimination claims, and wrongful dismissal cases.</span> </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col mb-5 rounded-lg ">
              <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-3 rounded-[16px]  dark:bg-[#3c3c3c]  pt-5">
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                    <p className="2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 text-center dark:text-white">Choosing Between Settlement Agreements and COT3</p>
                    <p className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-semibold mb-3 dark:text-white">Considerations:</p>
                    <ul className="list-disc ml-4">
                      <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Legal Advice Requirement:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Settlement agreements require independent legal advice, while COT3 agreements do not.</span> </li>
                      <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Facilitation:</span> <span className="text-[17px] dark:text-[#e0e0e0]"> COT3 agreements are facilitated by ACAS, providing an impartial mediator.</span> </li>
                      <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Speed and Simplicity:</span> <span className="text-[17px] dark:text-[#e0e0e0]">COT3 agreements can often be quicker and simpler to finalize.</span> </li>
                      <li className="mb-3 dark:text-white"><span className="font-bold text-[19px] dark:text-white">Nature of Dispute:</span> <span className="text-[17px] dark:text-[#e0e0e0]">Complex disputes might benefit from the detailed negotiation allowed in settlement agreements.</span> </li>
                    </ul> 
                    <p className="py-3 px-1 leading-normal text-[18px] dark:text-[#e0e0e0]">Both settlement agreements and COT3 agreements offer effective ways to resolve employment disputes without the need for lengthy and costly tribunal proceedings. Understanding the differences and appropriate contexts for each can help employers and employees reach fair and satisfactory outcomes.</p>
                  </div>
              </div>
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
        <Faqs />
      </div>
    </>
  );
}

export default Resource;
