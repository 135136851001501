import React from "react";
import "./PrivacyPolicy.css";
function PrivacyPolicy() {
  return (
    <>
      <div className="pt-[80px] dark:bg-[#292929] about font-serif privacy text-lg">
        <div>
          <header id="page-header" class="page-header overlay"></header>

          <div className="flex justify-center">
            <div className="container 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px]">
              <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-4 p-6 mb-4">
                <p className="mt-5 text-[40px] text-gray-400 font-extralight ">
                  Privacy Policy
                </p>
                <p className="mt-3 text-[20px] text-gray-400 font-extralight ">
                  Effective Date: December 2024
                </p>
                
              </div>
            </div>
          </div>

          <div className="flex justify-center ">
            <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col  mb-5 rounded-lg ">
              <div className="2xl:mx-5 xl:mx-5 lg:mx-5 md:mx-5 sm:mx-5 mx-4 rounded-[16px] bg-[#f3f3fd] dark:bg-[#3c3c3c] pt-5">
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                <p className=" text-lg dark:text-[#e0e0e0]">
                  <b>Hurr Consulting Ltd</b> is committed to protecting and
                  respecting your privacy. This Privacy Policy explains how we
                  collect, use, and protect your personal information in
                  compliance with the{" "}
                  <b>UK General Data Protection Regulation (UK GDPR)</b> and the{" "}
                  <b>Data Protection Act 2018.</b>
                </p>
                  <ul className="mt-4 ml-4 list-decimal">
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Information We Collect
                        </span>
                        <p className="pl-4 text-[17px] dark:text-[#e0e0e0]">
                          We may collect and process the following types of
                          personal information:
                        </p>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Personal Details:
                        </span>{" "}
                        <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Name, email address, phone number, and postal address.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Professional Information:
                        </span>
                        <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Job title, company name, and employment history (if
                          relevant).
                        </span>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Case-Related Information:
                        </span>
                        <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Details provided during legal consultations or case
                          assessments.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Payment Information:
                        </span>
                        <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Billing and payment details, where necessary
                        </span>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          Technical Data:
                        </span>
                        <span className=" text-[17px] dark:text-[#e0e0e0]">
                          IP address, browser type, and device information when
                          you visit our website.
                        </span>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                          How We Use Your Information
                        </span>
                        <p className=" text-[17px] dark:text-[#e0e0e0]">
                          We use personal information for the following
                          purposes:
                        </p>
                      </p>
                      <ul className="list-decimal ml-5 mt-2">
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                            Service Delivery:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                            To provide legal consultancy services, including
                            case assessments and document reviews.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                            Client Communication:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                            To respond to inquiries, schedule appointments, and
                            provide updates.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                            Legal Obligations:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                            To comply with legal, regulatory, and professional
                            requirements.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                            Marketing (Optional):
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                            With your explicit consent, we may send updates
                            about our services.
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Legal Basis for Processing
                        </span>
                        <p className=" text-[17px] dark:text-[#e0e0e0]">
                        We process personal information based on:
                        </p>
                      </p>
                      <ul className="list-decimal ml-5 mt-2">
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Contractual Necessity: 
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          To fulfil service agreements with clients.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Legal Obligation:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          To meet legal and regulatory requirements.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                            LLegitimate Interests:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          To manage and improve our services.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Consent:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          For marketing communications (if consent is provided).
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Data Sharing and Disclosure
                        </span>
                        <p className=" text-[17px] dark:text-[#e0e0e0]">
                        We only share personal information when necessary and as permitted by law,
including:
                        </p>
                      </p>
                      <ul className="list-decimal ml-5 mt-2">
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Service Providers:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Trusted third parties assisting in service delivery (e.g.,
                            IT support or payment processors).
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                           Legal Obligations:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Where disclosure is required by law or legal
proceedings.
                          </span>
                        </li>
                       <p>We will never sell your personal data to third parties.</p>
                        
                      </ul>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Data Security
                        </span>
                        <p className="pl-4 text-[17px] dark:text-[#e0e0e0]">
                        We implement appropriate technical and organizational measures to protect
                        personal data against unauthorized access, loss, or misuse.
                        </p>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Data Retention
                        </span>
                        <p className="pl-4 text-[17px] dark:text-[#e0e0e0]">
                        We retain personal data only as long as necessary for service delivery, legal
obligations, or legitimate business purposes. After this period, we securely delete
or anonymize the data.
                        </p>
                      </p>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Your Rights
                        </span>
                        <p className=" text-[17px] dark:text-[#e0e0e0]">
                        Under UK GDPR, you have the following rights:
                        </p>
                      </p>
                      <ul className="list-decimal ml-5 mt-2">
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Access:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Request access to your personal data.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Correction:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Request corrections to inaccurate or incomplete data.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Erasure:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Request deletion of your personal data, where legally applicable
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Objection:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Object to processing based on legitimate interests.
                          </span>
                        </li>
                        <li className="mb-3 dark:text-white">
                          <span className=" font-bold text-[19px] dark:text-white">
                          Data Portability:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          Request transfer of your personal data.
                          </span>
                        </li>
                        <p>To exercise these rights, contact us at <a href="mailto:mba@hurrconsulting.com" className="font-bold underline">mba@hurrconsulting.com</a></p>
                      </ul>
                    </li>
                    <li className="mb-3 dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Changes to This Privacy Policy
                        </span>
                        <p className="pl-4 text-[17px] dark:text-[#e0e0e0]">
                        We may update this Privacy Policy from time to time. Any changes will be posted
on this page with a revised effective date.
                        </p>
                      </p>
                    </li>
                    <li className=" dark:text-white">
                      <p>
                        <span className=" font-bold text-[19px] dark:text-white">
                        Contact Us
                        </span>
                      </p>
                    </li>
                        <p className=" text-[17px] dark:text-[#e0e0e0]">
                        If you have any questions or concerns about this Privacy Policy or our data practices,
please contact us at:
                        </p>
                      <p className=" font-bold">Hurr Consulting</p>
                      <a className=" font-bold underline" href="mailto:t mba@hurrconsulting.com">mba@hurrconsulting.com</a>
                    
                  </ul>
                </div>
                {/* bold content */}
                <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                <span className=" font-bold text-[19px] dark:text-white">
                Complaints:
                          </span>
                          <span className=" text-[17px] dark:text-[#e0e0e0]">
                          : If you are unsatisfied with our response, you have the right to lodge a
complaint with the <b>Information Commissioner’s Office (ICO)</b> in the UK
at <b>www.ico.org.uk</b>.
                          </span>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
