import React from "react";

function Disclaimer() {
  return (
    <>
      <div className="pt-[80px] dark:bg-[#292929] about font-serif">
        <div className="flex justify-center">
          <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col mb-5 rounded-lg">
            <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-4 rounded-[16px] bg-[#f3f3fd] dark:bg-[#3c3c3c]  pt-5">
              <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4">
                <p className="2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 2xl:leading-normal xl:leading-normal lg:leading-normal md:leading-tight leading-normal dark:text-white">
                  Disclaimer: Non-Reserved Legal Services
                </p>
                <p className="  leading-normal 2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] dark:text-[#e0e0e0]">
                  The services provided by Hurr Consulting are classified as
                  non-reserved legal services under the Legal Services Act 2007.
                  This means that we are not authorized to carry out reserved
                  legal activities, which are restricted to regulated legal
                  professionals such as solicitors, barristers, and licensed
                  conveyancers.
                </p>
              </div>
              <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                <p className="  2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-semibold mb-3 dark:text-white">
                  What We Do:
                </p>
                <ul className="list-disc ml-4  ">
                  <li className="mb-3 dark:text-white">
                    <span className="font-bold text-[19px] dark:text-white">
                      We provide legal consultancy services focused on:
                    </span>
                    <ul className="list-decimal ml-5 mt-2">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Employment law advice and guidance.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Reviewing and advising on employment-related
                          documents, including settlement agreements, COT3
                          agreements, and case assessments.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Drafting summaries, recommendations, and legal
                          guidance related to employment disputes
                        </span>
                         
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 dark:text-white">
                    <span className="font-bold text-[19px] dark:text-white">
                      One-Hour Consultation Call:
                    </span>{" "}
                    <span className="text-[17px] dark:text-[#e0e0e0]">
                      We’ll schedule a one-hour consultation call to:
                    </span>
                    <ul className="list-decimal ml-5 mt-2">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Discuss the details of your case and clarify any
                          uncertainties.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Explain the legal framework and how it applies to your
                          situation.
                        </span>
                         
                      </li>
                      <li className=" dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Provide tailored advice on next steps, including
                          negotiation strategies or further legal actions.
                        </span>
                         
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 dark:text-white">
                    <span className="font-bold text-[19px] dark:text-white">
                      Detailed Case Breakdown:
                    </span>
                    <span className="text-[17px] dark:text-[#e0e0e0]">
                      Following the consultation, you’ll receive a written
                      document summarizing:
                    </span>
                     {" "}
                    <ul className="list-decimal ml-5 mt-2">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          The key points discussed during the call.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          An assessment of your case’s strengths and weaknesses.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Recommendations for moving forward.
                        </span>
                         
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                <p className="  2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[25px] text-[24px] font-semibold mb-3 dark:text-white">
                  What We Do Not Do:
                </p>
                <ul className="list-disc ml-4  ">
                  <li className="mb-3 dark:text-white">
                    <span className="font-bold text-[19px] dark:text-white">
                      We do not perform reserved legal activities, including but
                      not limited to:
                    </span>
                    <ul className="list-decimal ml-5 mt-2">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Representing clients in court, tribunals, or other
                          legal proceedings.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Conducting litigation on behalf of clients.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Preparing instruments relating to the transfer of
                          property or other legally reserved tasks.
                        </span>
                         
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4  ">
                <p className="text-center 2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 dark:text-white">
                  Important Notice
                </p>
                <p className="py-3 px-1 leading-normal text-[18px] dark:text-[#e0e0e0]">
                  While we strive to provide accurate, reliable, and
                  high-quality legal guidance, Hurr Consulting is not a law firm
                  and is not regulated by the Solicitors Regulation Authority
                  (SRA) or any similar legal regulatory body. Engaging our
                  services does not create a solicitor-client relationship.
                </p>
                <p className="py-3 px-1 leading-normal text-[18px] dark:text-[#e0e0e0]">
                  If your legal matter requires representation in court,
                  conducting litigation, or other reserved legal services, we
                  recommend consulting a regulated legal professional or law
                  firm.
                </p>
                <p className="py-3 px-1 leading-normal text-[18px] dark:text-[#e0e0e0]">
                  For any questions or clarifications about our services, please
                  contact us directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Disclaimer;
