import React from "react";
import "./TermsOfService.css";

function TermsOfService() {
  return (
    <>
      <div className="pt-[80px] dark:bg-[#292929] about font-serif terms">
        <header id="page-header" class="page-header overlay"></header>
        <div className="flex justify-center">
          <div className="container flex justify-center 2xl:max-w-[1200px] xl:max-w-[1200px] lg:max-w-1200px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col mb-5 rounded-lg">
            <div className="2xl:m-5 xl:m-5 lg:m-5 md:m-5 sm:m-5 m-4 rounded-[16px] bg-[#f3f3fd] dark:bg-[#3c3c3c]  pt-5">
              <div className="2xl:px-8 xl:px-8 lg:px-8 md:px-8 px-4">
                <p className="2xl:text-[42px] xl:text-[42px] lg:text-[42px] md:text-[35px] sm:text-[35px] text-[29px] font-bold  mb-4 2xl:leading-normal xl:leading-normal lg:leading-normal md:leading-tight leading-normal dark:text-white">
                  Terms of Service
                </p>
                <p className=" leading-normal 2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] dark:text-[#e0e0e0]">
                  By purchasing services from Hurr Consulting, you agree to the
                  following terms:
                </p>
              </div>
              <div className="2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4  ">
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    1.Services Provided
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    We provide non-reserved legal services, including document
                    reviews, legal consultations, and case assessments. We do
                    not provide reserved legal services as defined by the Legal
                    Services Act 2007.
                  </p>
                </p>
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    2. Purchase and Payment
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    <ul className="list-disc ml-4">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Full payment is required upon purchasing a service.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          All prices are listed in GBP and are subject to
                          applicable taxes.
                        </span>
                         
                      </li>
                    </ul>
                  </p>
                </p>
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    3. Client Responsibilities
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    <ul className="list-disc ml-4">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          You must provide complete and accurate information.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          You are responsible for ensuring timely submission of
                          documents for review.
                        </span>
                         
                      </li>
                    </ul>
                  </p>
                </p>
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    4. Refund Policy
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    <ul className="list-disc ml-4">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Refunds are only issued if services have not yet
                          commenced.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Once work has started, fees are non-refundable.
                        </span>
                         
                      </li>
                    </ul>
                  </p>
                </p>
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    5. Limitation of Liability
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    To the maximum extent permitted by applicable law:
                  </p>
                  <p className="px-4 mt-2  text-lg dark:text-[#e0e0e0]">
                    <ul className="list-disc ml-4">
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          We are not responsible for any indirect, incidental,
                          special, or consequential damages, including loss of
                          revenue, profits, business, data, or anticipated
                          savings arising from the use of our services.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Our total liability, whether in contract, tort
                          (including negligence), or otherwise, is strictly
                          limited to the total amount paid by the client for the
                          relevant service.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          We are not liable for outcomes based on incomplete,
                          incorrect, or delayed information provided by the
                          client.
                        </span>
                         
                      </li>
                      <li className="mb-3 dark:text-white">
                        <span className="text-[17px] dark:text-[#e0e0e0]">
                          Nothing in this section limits liability for death,
                          personal injury caused by negligence, fraud, or other
                          liabilities that cannot be legally excluded.
                        </span>
                         
                      </li>
                    </ul>
                  </p>
                </p>
                <p className="mb-4 dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    6. Data Protection
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    We will handle your personal data in compliance with
                    applicable data protection laws, as described in our Privacy
                    Policy.
                  </p>
                </p>
                <p className=" dark:text-white">
                  <span className="font-bold text-[19px] dark:text-white">
                    7. Governing Law and Jurisdiction
                  </span>
                  <p className="px-4 mt-2 text-lg dark:text-[#e0e0e0]">
                    These terms are governed by the laws of England, Wales, and
                    Scotland. Any disputes arising under these Terms of Service
                    will be subject to the exclusive jurisdiction of the courts
                    in these regions.
                  </p>
                </p>
              </div>
              <p className=" 2xl:p-8 xl:p-8 lg:p-8 md:p-8 p-4 text-lg dark:text-[#e0e0e0]">
                By proceeding with your purchase, you confirm that you have
                read, understood, and accepted these Terms of Service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfService;
